import React, {useCallback, useContext, useEffect} from "react";
import {Drawer, Grid} from "@mui/material";
import {NavSidebarStateContext} from "~/nav-sidebar";
import DisbursementList from "~/disbursement/disbursement-list";
import DisbursementItem from "~/disbursement/disbursement-item";
import {useQueryParam} from "~/utils/use-query-param";

const DisbursementPage = () => {
  const [transferId, setTransferId] = useQueryParam<string|undefined>("transfer", undefined, {push: true, immediate: true});

  const {setCollapsed: setNavSidebarCollapsed} = useContext(NavSidebarStateContext);
  useEffect(() => {
    setNavSidebarCollapsed(transferId !== undefined);
  }, [transferId, setNavSidebarCollapsed]);

  const closeDrawer = useCallback(() => setTransferId(undefined), [setTransferId]);

  return (
    <Grid container spacing={1} sx={{width: "100%", height: "100%"}}>
      <DisbursementList transferId={transferId} onSelected={setTransferId}/>
      <Drawer anchor={"right"} open={!!transferId} onClose={closeDrawer} PaperProps={{sx: {width: "55vw"}}}>
        <DisbursementItem transferId={transferId} onClose={closeDrawer}/>
      </Drawer>
    </Grid>
  );
};


export default DisbursementPage;
