import React, {ReactNode, useEffect, useState} from "react";
import {LineItem, LineItemType} from "../line-item";
import {assertIn} from "../util";
import _, {compact} from "lodash";
import {StripePayout} from "~/stripe-payouts/stripe-payout";
import {TransferSummary} from "~/transfer/transfer-summary";
import Recon from "~/recon/recon";
import {StripePayoutTransactionWithTransfer} from "~/stripe-payout-transaction/stripe-payout-transaction";

export enum FlowState {
  SelectLineItemType = "select_line_item_type",
  SelectStripePayout = "select_stripe_payout",
  LinkStripePayoutTransactions = "link_stripe_payout_transactions",
  SelectTransfer = "select_transfer",
  Review = "review"
}

interface ReconStagingState {
  lineItem: LineItem;
  existingRecons: Recon[];
  flowState: FlowState;
  lineItemType?: ChoiceFlowTypes;
  stripePayout?: StripePayout;
  vitesseTransactionId?: string;
  transfers: TransferSummary[];
  transferToStripeTx: [number, string][];
}
export type ChoiceFlowTypes = Exclude<LineItemType, LineItemType.DisbursementWire>;

const lineItemTypeToFlow: { [key in ChoiceFlowTypes]: FlowState[] } = {
  [LineItemType.StripePayout]: [FlowState.SelectLineItemType, FlowState.SelectStripePayout, FlowState.LinkStripePayoutTransactions, FlowState.Review],
  [LineItemType.Deposit]: [FlowState.SelectLineItemType, FlowState.SelectTransfer, FlowState.Review],
  [LineItemType.VitesseTransaction]: [FlowState.SelectLineItemType, FlowState.SelectTransfer, FlowState.Review],
  [LineItemType.EpayTransaction]: [FlowState.SelectLineItemType, FlowState.SelectTransfer, FlowState.Review]
};

export function getFlow(lineItemType?: ChoiceFlowTypes): FlowState[] {
  if (lineItemType) {
    return lineItemTypeToFlow[lineItemType];
  } else {
    return [FlowState.SelectLineItemType];
  }
}

const lineItemTypeToNextState = (lineItemType: ChoiceFlowTypes) => _.first(_.drop(lineItemTypeToFlow[lineItemType], 1))!;

const makeReconStagingOps = (staging: ReconStagingState, setStaging: (_:ReconStagingState) => void) => ({
  lineItem: staging.lineItem,
  existingRecons: staging.existingRecons,
  flowState: staging.flowState,
  lineItemType: staging.lineItemType,
  transfers: staging.transfers,
  stripePayout: staging.stripePayout,
  transferToStripeTx: staging.transferToStripeTx,

  setLineItemType: (lineItemType: ChoiceFlowTypes) => {
    assertIn(staging.flowState, FlowState.SelectLineItemType);
    setStaging({...staging, lineItemType: lineItemType, flowState: lineItemTypeToNextState(lineItemType)});
  },

  setStripePayout: (stripePayout: StripePayout) => {
    assertIn(staging.flowState, FlowState.SelectStripePayout);
    setStaging({...staging, stripePayout: stripePayout, flowState: FlowState.LinkStripePayoutTransactions});
  },

  addTransfer: (transfer: TransferSummary, jumpToState: FlowState = FlowState.SelectTransfer) => {
    assertIn(staging.flowState, FlowState.SelectTransfer);
    setStaging({...staging, flowState: jumpToState, transfers: [...staging.transfers, transfer]});
  },

  approvePayoutTransactions: (txs: StripePayoutTransactionWithTransfer[]) => {
    assertIn(staging.flowState, FlowState.LinkStripePayoutTransactions);
    const transfers = compact(txs.map(v => v.transfer));
    const pairs: [number, string][] =
      txs
        .filter(v => v.transfer !== null)
        .map(v => [Number(v.transfer!.id), v.id]);

    setStaging({...staging, flowState: FlowState.Review, transferToStripeTx: pairs, transfers: transfers});
  },

  removeTransfer: (transfer: TransferSummary) => {
    assertIn(staging.flowState, FlowState.SelectTransfer);
    setStaging({...staging, flowState: FlowState.SelectTransfer, transfers: staging.transfers.filter(t => t.id !== transfer.id)});
  },

  jumpTo: (step: FlowState) => {
    setStaging({...staging, flowState: step});
  },

  reset: () => setStaging(makeDefaultState(staging.lineItem, staging.existingRecons))
});

export type ReconStagingOps = ReturnType<typeof makeReconStagingOps>;
const makeDefaultState = (lineItem: LineItem, existingRecons: Recon[]): ReconStagingState => {
  if (lineItem.target?.type === "deposit_target") {
    return {lineItem, existingRecons, lineItemType: LineItemType.Deposit, flowState: lineItemTypeToNextState(LineItemType.Deposit), transfers: [], transferToStripeTx: []};
  } else if (lineItem.target?.type === "epay_transaction_target") {
    return {lineItem, existingRecons, lineItemType: LineItemType.EpayTransaction, flowState: lineItemTypeToNextState(LineItemType.EpayTransaction), transfers: [], transferToStripeTx: []};
  } else if (lineItem.target?.type === "stripe_payout_target") {
    return {lineItem, existingRecons, lineItemType: LineItemType.StripePayout, flowState: lineItemTypeToNextState(LineItemType.StripePayout), transfers: [], transferToStripeTx: []};
  } else if (lineItem.target?.type === "vitesse_transaction_target") {
    return {lineItem, existingRecons, lineItemType: LineItemType.VitesseTransaction, flowState: lineItemTypeToNextState(LineItemType.VitesseTransaction), transfers: [], transferToStripeTx: []};
  }
  return {lineItem, existingRecons, flowState: FlowState.SelectLineItemType, transfers: [], transferToStripeTx: []};
};

export const ReconStagingProvider = ({children, lineItem, existingRecons}: {children: ReactNode, lineItem: LineItem, existingRecons: Recon[]}) => {
  // Could be made better with useReducer instead, but that's more boilerplate
  const defaultState = makeDefaultState(lineItem, existingRecons);
  const [staging, setStaging] = useState<ReconStagingState>(defaultState);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setStaging(defaultState), [lineItem.id]);

  const ops = makeReconStagingOps(staging, (s) => { console.log(s); setStaging(s); });

  return (
    <ReconStagingContext.Provider value={ops}>
      {children}
    </ReconStagingContext.Provider>
  );
};

export const ReconStagingContext = React.createContext<ReconStagingOps>(undefined!);
