import {Stack, Typography} from "@mui/material";
import React from "react";
import {StripeView} from "~/recon/components/recon-review";
import {ExistingLineItem} from "~/recon/recon-page";
import BasicTable from "~/components/basic-table";
import {startCase} from "lodash";
import {MoneyFormat} from "~/utils/money";
import Recon from "~/recon/recon";
import {VirtualAccountPreviewOpt} from "~/recon/components/account-link-info";

export const PreviousRecon = ({recons, stripePayout}: ExistingLineItem) => {
  const columns = [
    {id: "id", title: "R.ID"},
    {id: "state", title: "State", valueFormatter: startCase},
    {id: "amount", title: "Recon Amount", valueGetter: (v: Recon) => MoneyFormat.formatBigNumber(v.amount, v.transfer.toAccount?.currency)},
    {id: "transfer.id", title: "ID", valueGetter: (v: Recon) => v.transfer.id},
    {id: "transfer.amount", title: "Tr. Amount", valueGetter: (v: Recon) => MoneyFormat.formatBigNumber(v.transfer.amount, v.transfer.toAccount?.currency)},
    {id: "transfer.intention", title: "Intention", valueFormatter: startCase},
    {id: "transfer.state", title: "State", valueFormatter: startCase},
    {id: "transfer.description", title: "Description", valueGetter: (v: Recon) => v.transfer.intentionData?.description},
    {id: "transfer.fromAccount", title: "From", valueGetter: (v: Recon) => v.transfer.fromAccount, renderComponent: VirtualAccountPreviewOpt},
    {id: "transfer.toAccount", title: "To", valueGetter: (v: Recon) => v.transfer.toAccount, renderComponent: VirtualAccountPreviewOpt}
  ];

  return (
    <Stack spacing={4}>
      <BasicTable columns={columns} rows={recons}/>
      {!!stripePayout && <Typography variant="body2" mt={4}>Stripe payout:</Typography>}
      {!!stripePayout && <StripeView payout={stripePayout}/>}
    </Stack>
  );
};
