import React, {useState} from "react";

import {Page} from "./components/page";
import {Box, FormControl, InputLabel, MenuItem, Select, Stack, Typography} from "@mui/material";
import {useQuery} from "react-query";
import TransferStats from "./repositories/transfer-stats";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import {TransferAmountStat} from "./transfer/transfer-stats";
import {groupBy, toPairs} from "lodash";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import en from "date-fns/locale/en-US";
import {format, formatISO, subMonths} from "date-fns";
import {RenderIf} from "~/recon/util";

const Dot = (_: any) => {
  return null;
};

const formatDate = (milliseconds: number) => {
  return (new Date(milliseconds)).toLocaleDateString();
};
const formatDateLong = (ms: number) => format(new Date(ms), "PPPP");

function transformAmounts(data: TransferAmountStat[]) {
  return toPairs(groupBy(data, (d) => formatDate(d.time.epochMilliseconds))).map(([_, data]) => ({
    x: data[0].time.epochMilliseconds,
    time: new Date(data[0].time.epochMilliseconds),
    pending: data.find(d => d.state === "pending")?.amount ?? 0,
    done: data.find(d => d.state === "done")?.amount ?? 0,
    failed: data.find(d => d.state === "failed")?.amount ?? 0
  }));
}

function transformCounts(data: TransferAmountStat[]) {
  return toPairs(groupBy(data, (d) => formatDate(d.time.epochMilliseconds))).map(([_, data]) => ({
    x: data[0].time.epochMilliseconds,
    time: new Date(data[0].time.epochMilliseconds),
    pending: data.find(d => d.state === "pending")?.count ?? 0,
    done: data.find(d => d.state === "done")?.count ?? 0,
    failed: data.find(d => d.state === "failed")?.count ?? 0
  }));
}

type TimeBucket = "day" | "week" | "month";

interface TransferByStateTick {
  x: number;
  time: Date;
  pending: number;
  failed: number;
  done: number;
}

const ChartWrapper = ({title, data, height, Chart}: {title: string, height: number, data: TransferByStateTick[], Chart: React.FC<{data: TransferByStateTick[], height: number}>}) => (
  <>
    <Typography variant="body2" sx={{mb: 2}}>{title}</Typography>
    <RenderIf cond={data.length === 0}>
      <Typography variant="body2" sx={{mb: 2, ml: 2, opacity: 0.6}}>No data for selected criteria</Typography>;
    </RenderIf>
    <RenderIf cond={data.length > 0}>
      <Chart data={data} height={height}/>
    </RenderIf>
  </>
);

const TimeseriesByState = ({data, height}: {data: TransferByStateTick[], height: number}) => (
  <ResponsiveContainer width="100%" height={height}>
    <LineChart data={data}>
      <CartesianGrid strokeDasharray="1" strokeWidth={0.25}/>
      <XAxis dataKey="x" type="number" interval="preserveStartEnd" tick={{fontSize: "0.7rem"}} tickFormatter={formatDate} domain={[data[0]?.x, data[data.length - 1]?.x]} stroke="white"/>
      <YAxis tick={{fontSize: "0.7rem"}} domain={["dataMin", "dataMax"]} scale="sqrt" stroke="white"/>
      <Tooltip allowEscapeViewBox={{y: true}} contentStyle={{backgroundColor: "#282323"}} labelFormatter={formatDateLong}/>
      <Legend/>
      <Line type="linear" name="Pending" dataKey="pending" stroke="#FFB84C" fill="#FFB84C" dot={<Dot/>} strokeWidth={2}/>
      <Line type="linear" name="Completed" dataKey="done" stroke="#22A699" fill="#22A699" dot={<Dot/>} strokeWidth={2}/>
      <Line type="linear" name="Failed" dataKey="failed" stroke="#F24C3D" fill="#F24C3D" dot={<Dot/>} strokeWidth={2}/>
    </LineChart>
  </ResponsiveContainer>
);

const BucketsByState = ({data, height}: {data: TransferByStateTick[], height: number}) => (
  <ResponsiveContainer width="100%" height={height}>
    <BarChart data={data}>
      <CartesianGrid strokeDasharray="1" strokeWidth={0.25}/>
      <XAxis dataKey="time" type="category" interval="preserveStartEnd" tick={{fontSize: "0.7rem"}} tickFormatter={(t: Date) => t.toLocaleDateString()} stroke="white"/>
      <YAxis tick={{fontSize: "0.7rem"}} domain={["dataMin", "dataMax"]} stroke="white"/>
      <Tooltip allowEscapeViewBox={{y: true}} contentStyle={{backgroundColor: "#232020"}} labelFormatter={formatDateLong} cursor={{fill: "#232020", strokeWidth: 2}}/>
      <Legend/>
      <Bar name="Pending" dataKey="pending" stroke="#FFB84C" fill="#FFB84C"/>
      <Bar name="Completed" dataKey="done" stroke="#22A699" fill="#22A699"/>
      <Bar name="Failed" dataKey="failed" stroke="#F24C3D" fill="#F24C3D"/>
    </BarChart>
  </ResponsiveContainer>
);

export const Home = () => {
  const [fromDate, setFromDate] = useState<Date | null>(subMonths(new Date(), 3));
  const [toDate, setToDate] = useState<Date | null>(new Date());
  const [bucket, setBucket] = useState<TimeBucket>("week");

  const params = {
    from: formatISO(fromDate!),
    to: formatISO(toDate!),
    bucket: bucket
  };
  const amountsQuery = useQuery(TransferStats.amounts(params));
  const amountsData = amountsQuery.data ? transformAmounts(amountsQuery.data) : [];
  const countData = amountsQuery.data ? transformCounts(amountsQuery.data) : [];

  console.log(countData);
  return (
    <Page title="Dashboard">
      <Stack direction="row" spacing={2} sx={{mb: 4}}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={en}>
          <DatePicker label="From" value={fromDate} slotProps={{textField: {size: "small"}}} onChange={(date) => setFromDate(date)}/>
          <DatePicker label="To" value={toDate} slotProps={{textField: {size: "small"}}} onChange={(date) => setToDate(date)}/>
          <FormControl>
            <InputLabel id="bucket-selector">Bucket</InputLabel>
            <Select value={bucket} onChange={(e) => setBucket(e.target.value as TimeBucket)} variant="outlined" label="Bucket" size="small" labelId="bucket-selector">
              <MenuItem value="day">Day</MenuItem>
              <MenuItem value="week">Week</MenuItem>
              <MenuItem value="month">Month</MenuItem>
            </Select>
          </FormControl>
        </LocalizationProvider>
      </Stack>
      <Box>
        <ChartWrapper title={"Transfer amounts per week"} data={amountsData} height={350} Chart={TimeseriesByState}/>
        <ChartWrapper title={"Number of transfers per week"} data={countData} height={350} Chart={BucketsByState}/>
      </Box>
    </Page>
  );
};
