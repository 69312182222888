import {getLineItemBank, getLineItemDescription, LineItem, LineItemType} from "~/recon/line-item";
import {MoneyFormat} from "~/utils/money";
import {Alert, Stack, Typography} from "@mui/material";
import BasicTable from "~/components/basic-table";
import Button from "@mui/material/Button";
import React from "react";
import {ReconStagingOps} from "~/recon/components/recon-staging";
import {labelForLineItemType} from "~/recon/util";
import {TransferSummary} from "~/transfer/transfer-summary";
import {startCase, sumBy} from "lodash";
import {StripePayout} from "~/stripe-payouts/stripe-payout";
import LineItems, {ReconRequest, ReconRequestType} from "~/repositories/line-items";
import {useMutation, useQueryClient} from "react-query";
import {LoadingButton} from "@mui/lab";
import DetailGrid from "~/utils/detail-grid";
import {Temporal} from "@js-temporal/polyfill";
import {localeDateString} from "~/utils/temporal";
import {VirtualAccountPreviewOpt} from "~/recon/components/account-link-info";

interface Props {
  staging: ReconStagingOps;
}

export function LineItemView({lineItem}: {lineItem: LineItem}) {
  return (
    <DetailGrid>
      <Typography variant="body2" color="text.secondary">Id:</Typography>
      <Typography variant="body2">{lineItem.id}</Typography>

      <Typography variant="body2" color="text.secondary">Escrow:</Typography>
      <Typography variant="body2">{getLineItemBank(lineItem)}</Typography>

      <Typography variant="body2" color="text.secondary">Amount:</Typography>
      <Typography variant="body2">{MoneyFormat.formatNumber(lineItem.amount.toNumber(), lineItem.currency)}</Typography>

      <Typography variant="body2" color="text.secondary">Type:</Typography>
      <Typography variant="body2">{lineItem.lineItemType() === null ? "N/A" : labelForLineItemType(lineItem.lineItemType()!)}</Typography>

      <Typography variant="body2" color="text.secondary">Description:</Typography>
      <Typography variant="body2">{getLineItemDescription(lineItem)}</Typography>

      <Typography variant="body2" color="text.secondary">Date:</Typography>
      <Typography variant="body2">{lineItem.date.toLocaleString()}</Typography>
    </DetailGrid>
  );
}

export function TransferList({rows}: {rows: TransferSummary[]}) {
  const columns = [
    {id: "id", title: "ID"},
    {id: "intention", title: "Intention", valueFormatter: startCase},
    {id: "state", title: "State", valueFormatter: startCase},
    {id: "description", title: "Description", valueGetter: (v: TransferSummary) => v.intentionData?.description},
    {id: "fromAccount", title: "From", valueGetter: (v: TransferSummary) => v.fromAccount, renderComponent: VirtualAccountPreviewOpt},
    {id: "toAccount", title: "To", valueGetter: (v: TransferSummary) => v.toAccount, renderComponent: VirtualAccountPreviewOpt},
    {id: "amount", title: "Amount", valueGetter: (v: TransferSummary) => MoneyFormat.formatBigNumber(v.amount, v.currency)}
  ];
  const total = sumBy(rows, _ => _.amount.toNumber());
  return (
    <BasicTable columns={columns} rows={rows} total={MoneyFormat.formatNumber(total, rows[0]?.currency)}/>
  );
}

export function StripeView({payout}: {payout: StripePayout}) {
  const columns = [
    {id: "id", title: "ID"},
    {id: "stripeAccountId", title: "Account Id"},
    {id: "status", title: "Status", valueFormatter: startCase},
    {id: "description", title: "Description"},
    {id: "arrivalDate", title: "Arrival Date", valueFormatter: (v: Temporal.Instant) => localeDateString(v)},
    {id: "amount", title: "Amount", valueGetter: (v: StripePayout) => MoneyFormat.formatBigNumber(v.amount, v.currency)}
  ];

  return (
    <BasicTable columns={columns} rows={[payout]}/>
  );
}

// bljak
function makePayload({transfers, stripePayout, lineItemType, lineItem, transferToStripeTx}: ReconStagingOps): (ReconRequest | undefined) {
  switch (lineItemType) {
    case LineItemType.StripePayout:
      return {lineItemId: lineItem.id, lineItemTargetType: "stripe_payout", type: ReconRequestType.StripePayout, stripePayoutId: stripePayout!.id, transfersToPayoutTransactions: transferToStripeTx};
    case LineItemType.Deposit:
      return {lineItemId: lineItem.id, lineItemTargetType: "deposit", type: ReconRequestType.Deposit, adjust: false, transferIds: transfers.map(_ => Number(_.id))};
    case LineItemType.EpayTransaction:
      return {lineItemId: lineItem.id, lineItemTargetType: "epay_transaction", type: ReconRequestType.EpayTransaction, transferId: Number(transfers[0]!.id)}; // fix this
    case LineItemType.VitesseTransaction:
      return {lineItemId: lineItem.id, lineItemTargetType: "vitesse_transaction", type: ReconRequestType.VitesseTransaction, transferId: Number(transfers[0]!.id), vitesseTransactionId: "TODO"}; // fix this
  }
}

export function Error({error}: {error: any}) {
  return (
    <Alert severity="error">{(error || {}).message || "Unknown error happened"}</Alert>
  );
}

export default function({staging}: Props) {
  const backendId = staging.lineItem.backendId;
  const client = useQueryClient();
  const {mutateAsync, isLoading, isError, isSuccess, error} = useMutation(LineItems.reconcile(backendId));
  const isStripe = staging.lineItemType === LineItemType.StripePayout;

  const onCancel = () => staging.reset();
  const onConfirm = async () => {
    const payload = makePayload(staging)!;
    const resp = await mutateAsync(payload);
    await client.refetchQueries(["line_items", backendId], {exact: false});
    return resp;
  };

  const existingTransfers = staging.existingRecons.map(r => r.transfer);

  return (
    <Stack>
      <Typography variant="body2" sx={{mb: 2}}>Confirm that the line item:</Typography>
      <LineItemView lineItem={staging.lineItem}/>
      <Typography variant="body2" mt={4}>Should be reconciled with:</Typography>
      {isStripe ? <StripeView payout={staging.stripePayout!}/> : <TransferList rows={[...existingTransfers, ...staging.transfers]}/>}
      {isStripe && <Typography variant="body1" mt={4}>And linked to these transfers:</Typography>}
      {isStripe && <TransferList rows={staging.transfers}/>}
      {isError && <Error error={error}/>}
      {isSuccess && <Alert severity="success">Changes saved successfully!</Alert>}
      <Stack direction="row" sx={{mb: 2, mt: 2}} justifyContent="space-between">
        <LoadingButton loading={isLoading} variant="contained" onClick={onConfirm} size="small" sx={{mt: 1, mr: 1}}>Submit</LoadingButton>
        <Button variant="contained" onClick={onCancel} size="small" color={"error"} sx={{mt: 1, mr: 1}}>Cancel</Button>
      </Stack>
    </Stack>
  );
}
