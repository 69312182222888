import {JsonDto, jsonProp} from "~/utils/json-validation";
import {JsonTypes} from "~/utils/json-validation/types";
import {BigNumber} from "bignumber.js";
import {TransferSummary} from "~/transfer/transfer-summary";

export default class Recon extends JsonDto {
    public static readonly STATES = ["pending", "done"] as const;

    @jsonProp(JsonTypes.id)
    public readonly id!: number;

    @jsonProp(JsonTypes.dto(TransferSummary))
    public readonly transfer!: TransferSummary;

    @jsonProp(JsonTypes.stringUnion(["pending", "done"]))
    public readonly state!: Recon.State;

    @jsonProp(JsonTypes.bigNumber)
    public readonly amount!: BigNumber;

    @jsonProp(JsonTypes.id, {optional: true})
    public readonly vitesseAccountEntryId!: number|null;

    @jsonProp(JsonTypes.id, {optional: true})
    public readonly stripePayoutTransactionId!: number|null;

    public constructor(init?: unknown) {
        super();
        this.init(Recon, init);
    }
}

export namespace Recon {
    export type State = (typeof Recon.STATES)[number];
}
